import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../components/imports'

import { APP_NAME } from '../constants/general'

import Func from '../class/Func'

import Categories from '../class/Categories';
import Annonces from '../class/Annonces';

function CategoriesPage() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [categorie, setCategorie] = useState(false)
    const [categories, setCategories] = useState([])

    const [annonces, setAnnonces] = useState(false)

    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(2)

    const [filtreTaille, setFiltreTaille] = useState(false)
    const [filtreType, setFiltreType] = useState(false)
    const [filtrePrixMin, setFiltrePrixMin] = useState(false)
    const [filtrePrixMax, setFiltrePrixMax] = useState(false)

    const getCategorie = async () => {

        let results = await Categories.categorieByUrl(url)
        
        if(results.success){

            console.log(results.data)

            setCategorie(results.data)
            setCategories(results.categories)

            document.title = results.data.name + " - " + APP_NAME
            
            getAnnonces(results.data.idC)

        }else{

            navigate('/404')

        }
    
    }

    const getAnnonces = async (id, option = false, load = true) => {

        if(load) setAnnonces(false)

        if(!option){
            setFiltreTaille(false)
            setFiltreType(false)
            setFiltrePrixMin(false)
            setFiltrePrixMax(false)
        }

        let results = await Annonces.annoncesByCategorie(id, option)

        setLoading(false)

        if(results.success){

            setAnnonces(results.data)

        }else{

            if(results.empty){

                setAnnonces(results.message)

            }else{

                navigate('/404')

            }

        }
    
    }

    const changeFilter = (id, type, val) => {

        let taille = filtreTaille
        let type_ = filtreType
        let p_min = filtrePrixMin
        let p_max = filtrePrixMax

        if(type == 'taille'){
            taille = val
            setFiltreTaille(val)
        }

        if(type == 'type'){
            type_ = val
            setFiltreType(val)
        }

        if(type == 'min'){
            p_min = val
            setFiltrePrixMin(val)
        }

        if(type == 'max'){
            p_max = val
            setFiltrePrixMax(val)
        }

        getAnnonces(id, {'taille': taille, 'type': type_, 'min': p_min, 'max': p_max})

    }

    const voirPlus = () => {

        let taille = filtreTaille
        let type_ = filtreType
        let p_min = filtrePrixMin
        let p_max = filtrePrixMax

        setLoading(true)

        getAnnonces(categorie.idC, {'taille': taille, 'type': type_, 'min': p_min, 'max': p_max, limit: limit}, false)

        setLimit(limit + 1)

    }

    useEffect(() => {

        getCategorie()

        document.title = "Categorie - " + APP_NAME

        document.body.classList.remove('mn')

        Func.choixSelect('#taille ._choix')

    }, [url])

    return (

        <div>

            <Header />

            <div className="categories">

                <HeaderBox />

                <div className="c-0 pos-r content">

                    <div className="flex mb30">
                        <div className="col-2"></div>
                        <div className="col-78">

                            <span className='textUnd mb30 d-block m_mb_0 m_op_0'>{ categorie.name }</span>

                            <div className="wrap textUp fw5 m_profile_menu m_p10 m_col_10">
                                
                                {

                                    categories

                                    &&

                                    categories.map(element => <Link to={"/categorie/" + element.url} className={categorie.url == element.url ? "btn brc4 cl1 bg4 mr10" : "btn brc4 cl2 mr10"} key={element.url}>{element.name}</Link>)

                                }

                            </div>

                        </div>
                    </div>

                    <div className="flex">

                        <div className="col-2 aside m_p10 m_mb_40">

                            {

                                (filtreTaille || filtreType || filtrePrixMin || filtrePrixMax)

                                &&

                                <>
                                    <span className='cl4 mr5 icon' onClick={ e => navigate(0) }>w</span>
                                    <span class="fz08">Supprimer</span>
                                </>

                            }

                            <span className='cl4 t'>Taille</span>
                            
                            <div className="flex m_flex jc-start m_col_4" id='taille' key={"taille_0001"}>
                                
                                {
                                    categorie.url == "plantes"

                                    &&

                                    <div className='_choix' onClick={ e => changeFilter(categorie.idC, 'taille', 'Gr') } title="Graine">
                                        <span className={ filtreTaille == 'Gr' ? 'opt bg3 h mr10 icon' : 'opt bg2 h mr10 icon'}>x</span>
                                    </div>

                                }

                                <div className='_choix' onClick={ e => changeFilter(categorie.idC, 'taille', 'S') } title="5-30 cm">
                                    <span className={ filtreTaille == 'S' ? 'opt bg3 h mr10' : 'opt bg2 h mr10'}>S</span>
                                </div>

                                <div className='_choix' onClick={ e => changeFilter(categorie.idC, 'taille', 'M') } title="30-60 cm">
                                    <span className={ filtreTaille == 'M' ? 'opt bg3 h mr10' : 'opt bg2 h mr10'}>M</span>
                                </div>

                                <div className='_choix' onClick={ e => changeFilter(categorie.idC, 'taille', 'L') } title="60-90 cm">
                                    <span className={ filtreTaille == 'L' ? 'opt bg3 h mr10' : 'opt bg2 h mr10'}>L</span>
                                </div>

                                <div className='_choix' onClick={ e => changeFilter(categorie.idC, 'taille', 'XL') } title="90-130 cm">
                                    <span className={ filtreTaille == 'XL' ? 'opt bg3 h mr10' : 'opt bg2 h mr10'}>XL</span>
                                </div>
                                
                            </div>

                            {

                                categorie.url == 'plantes'

                                &&

                                <>
                                    <span className='cl4 t'>Type de plantes</span>

                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '1') } className={ filtreType == '1' && 'cl4 fw6'}>Plantes vertes</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '2') } className={ filtreType == '2' && 'cl4 fw6'}>Plantes rares</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '6') } className={ filtreType == '6' && 'cl4 fw6'}>Plantes aromatiques</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '3') } className={ filtreType == '3' && 'cl4 fw6'}>Plantes grasses</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '4') } className={ filtreType == '4' && 'cl4 fw6'}>Plantes fleuries</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '7') } className={ filtreType == '7' && 'cl4 fw6'}>Graines et bulbes</p>
                                    <p onClick={ e => changeFilter(categorie.idC, 'type', '5') } className={ filtreType == '5' && 'cl4 fw6'}>Autres</p>
                                </>

                            }

                            {

                            categorie.url == 'accessoires'

                            &&

                            <>
                                <span className='cl4 t'>Type d'accessoires</span>

                                <p onClick={ e => changeFilter(categorie.idC, 'type', '1') } className={ filtreType == '1' && 'cl4 fw6'}>Arrosoirs</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '2') } className={ filtreType == '2' && 'cl4 fw6'}>Tuteurs</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '3') } className={ filtreType == '3' && 'cl4 fw6'}>Oyas</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '4') } className={ filtreType == '4' && 'cl4 fw6'}>Autres</p>
                            </>

                            }

                            {

                            categorie.url == 'pots'

                            &&

                            <>
                                <span className='cl4 t'>Type de pots</span>

                                <p onClick={ e => changeFilter(categorie.idC, 'type', '1') } className={ filtreType == '1' && 'cl4 fw6'}>Terre cuite</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '2') } className={ filtreType == '2' && 'cl4 fw6'}>Plastique</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '3') } className={ filtreType == '3' && 'cl4 fw6'}>Ceramique</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '4') } className={ filtreType == '4' && 'cl4 fw6'}>Autres</p>
                            </>

                            }

                            {

                            categorie.url == 'livres'

                            &&

                            <>
                                <span className='cl4 t'>Type de livres</span>

                                <p onClick={ e => changeFilter(categorie.idC, 'type', '1') } className={ filtreType == '1' && 'cl4 fw6'}>Botanique</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '2') } className={ filtreType == '2' && 'cl4 fw6'}>Potager</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '3') } className={ filtreType == '3' && 'cl4 fw6'}>Arbres</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '4') } className={ filtreType == '4' && 'cl4 fw6'}>Herboristerie</p>
                                <p onClick={ e => changeFilter(categorie.idC, 'type', '5') } className={ filtreType == '5' && 'cl4 fw6'}>Autres</p>
                            </>

                            }

                            <span className='cl4 t'>Prix</span>
                            
                            <div className="grid2 cat_filter m_flex">
                                
                                <div>
                                    <label>Min</label>
                                    <input type="number" placeholder='€' className='prix_min col-10 brc4' value={filtrePrixMin} onChange={ e => changeFilter(categorie.idC, 'min', e.target.value) }/>
                                </div>

                                <div>
                                    <label>Max</label>
                                    <input type="number" placeholder='€' className='prix_max col-10 brc4' value={filtrePrixMax} onChange={ e => changeFilter(categorie.idC, 'max', e.target.value) }/>
                                </div>

                            </div>


                            </div>

                            <div className="col-78 m_mb_40">

                            {

                            annonces

                            ?

                                typeof annonces == 'object'

                                ?

                                <>

                                    <div className="grid4 articles small">

                                        { annonces.map(element => <Article data={element} key={element.idA} />)}
                                        
                                    </div>

                                    {loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>}

                                    {

                                        annonces.length % 12 == 0

                                        &&

                                        <div className='flex jc-center mt30 m_flex m_p10 m_mb_10'>
                                            <span className='btn bg4 cl1' onClick={voirPlus}>Voir plus</span>
                                        </div>

                                    }

                                </>

                                :

                                <p>Aucune annonce !</p>

                            :

                                <div className='flex jc-center mt50 mb50'>
                                    <div className="lds-dual-ring col"></div>
                                </div>

                            }

                            </div>

                        </div>

                </div>

                <Footer />
                
            </div>

        </div>

    )

}

export default CategoriesPage