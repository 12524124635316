import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import Func from '../../class/Func'

import Categories from '../../class/Categories';
import Annonces from '../../class/Annonces';

function PlantSittingAnnonce() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [annonces, setAnnonces] = useState(false)

    const [loading, setLoading] = useState(false)

    const getAnnonce = async (id, option = false, load = true) => {

        if (load) setAnnonces(false)

        let results = await Annonces.annoncesByCategorie(id, option)

        setLoading(false)

        if (results.success) {

            setAnnonces(results.data)

        } else {

            if (results.empty) {

                setAnnonces(results.message)

            } else {

                navigate('/404')

            }

        }

    }

    useEffect(() => {

        // getCategorie()

        document.title = "Plant Sitting Annonce - " + APP_NAME

        document.body.classList.remove('mn')

    }, [url])

    return (

        <div>

            <Header />

            <div className="plantsitting">

                <HeaderBox />

                <div className="single">

                    <div className='content'>

                        <div className='c'>

                            <div className="bg7 br8 p20 mb20">
                                <div className="flex">
                                    <div className='col-7'>
                                        <span className='fw5 fz12'>Prix par catégorie</span>
                                        <span className='fw3 fz08 d-block'>par passage,  par semaine de gardiennage</span>

                                        <div className="grid2 mt30 mb30">
                                            <div>
                                                <span className='fw3 d-block fz12'>Gardiennage</span>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 35 plantes</p>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 70 plantes</p>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 100 plantes</p>
                                            </div>

                                            <div>
                                                <span class='fw3 d-block fz12'>Arrosage</span>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 35 plantes</p>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 70 plantes</p>
                                                <p><span className="cl3 fz13 mr10">0,00€</span> entre 1 & 100 plantes</p>
                                            </div>
                                        </div>

                                        <div className="grid2 col-6 mb20">
                                            <span class='fw3'>Fréquence / sem</span>
                                            <span class='fw5'>2 passages</span>
                                        </div>

                                        <div className="grid2 col-6 mb20">
                                            <span class='fw3'>Localisation</span>
                                            <span class='fw5'>Paris, 15ème</span>
                                        </div>

                                    </div>

                                    <div className="col-3">
                                        <Link to={'/plant-sitting/reserver'} className="btn brc4 brc1solid cl1 bg4 ta-center d-block mb10">Réserver</Link>
                                        {/* <a href="" className="btn brc4 brc1solid cl4 bgt ta-center d-block mb10">Afficher calendrier</a> */}
                                        <a href="" className="btn brc4 brc1solid cl4 bgt ta-center d-block mb10"><span className='icon'>j</span> Favoris</a>
                                        <a href="" className="btn brc4 brc1solid cl1 bg4 ta-center d-block mb10">Envoyer un message</a>
                                    </div>
                                </div>

                                <p>J’arrose et je garde vos plantes ex : parler de votre expérience en plant-addiction, arrosage, disponibilité... ex : parler de votre expérience en plant-addiction, arrosage, disponibilité...</p>

                            </div>

                            <div class="flex bg2 br5 p10 pr20 pl20 ai-center">

                                <div class='user'>

                                    <div class="av">
                                        <img src={ Func.avatar(0) } />
                                    </div>

                                    <span class='t cl5 mr10'>Pseudo</span>

                                    <div class="rating">
                                        <span class='icon'>p</span>
                                        <span class='icon'>p</span>
                                        <span class='icon'>p</span>
                                        <span class='icon'>p</span>
                                        <span class='icon'>p</span>
                                    </div>

                                </div>

                                <a href="" class='cl5 fz09'>(37) articles disponibles</a>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        </div>

    )

}

export default PlantSittingAnnonce