import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import Func from '../../class/Func'

import Categories from '../../class/Categories';
import Annonces from '../../class/Annonces';

function PlantSittingProposer() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [annonces, setAnnonces] = useState(false)

    const [loading, setLoading] = useState(false)

    const getAnnonce = async (id, option = false, load = true) => {

        if (load) setAnnonces(false)

        let results = await Annonces.annoncesByCategorie(id, option)

        setLoading(false)

        if (results.success) {

            setAnnonces(results.data)

        } else {

            if (results.empty) {

                setAnnonces(results.message)

            } else {

                navigate('/404')

            }

        }

    }

    useEffect(() => {

        // getCategorie()

        document.title = "Proposer un plant sitting - " + APP_NAME

        document.body.classList.remove('mn')

    }, [url])

    return (

        <div>

            <Header />

            <div className="plantsitting">

                <HeaderBox />

                <div className="single">

                    <div className='content'>

                        <div className='c'>

                            <form action="" method="POST" class="gla-form">

                                <div className="bg7 br8 p40 mt20 ta-center">

                                    <div>
                                        <label>
                                            <span>Catégorie</span>
                                            <select>
                                                <option value="0">Gardiennage</option>
                                                <option value="1">Arrosage</option>
                                            </select>
                                        </label>
                                        <label>
                                            <span>Mon adresse</span>
                                            <input type="text" name="nom" placeholder="ex : Paris" rows="1" required="true" />
                                        </label>
                                        <label>
                                            <span>Titre</span>
                                            <input type="text" name="nom" placeholder="ex : L’Aspidistra Elatior" rows="1" required="true" />
                                        </label>
                                        <label>
                                            <span>Description</span>
                                            <textarea name="description" placeholder="ex : Parfaite pour sublimer votre salle de bain !" required="true"></textarea>
                                        </label>
                                    </div>

                                </div>

                                <div className="bg7 br8 p40 mt20">

                                    <div>

                                        <span className='fw5 d-block'>Prix par catégorie</span>
                                        <span className='fw3 fz08 d-block mb30'>par passage, par semaine de gardiennage</span>

                                        <label>
                                            <span>Gardiennage</span>
                                            <div className="grid3 col-65">
                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p class='fz09 ml10'>1 à 35 plantes</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div clasNames='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 à 70 plantes</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 à 100 plantes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>

                                        <label>
                                            <span>Arrosage</span>
                                            <div class="grid3 col-65">
                                                
                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 à 35 plantes</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 à 70 plantes</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type="number" name="nom" placeholder="0,00€" required="true" class='ml20 fz11' />
                                                    <div clasNames='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 à 100 plantes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>

                                        <label>
                                            <div>
                                                <span>Fréquence</span>
                                                <span className='fw3 fz08 d-block'>par semaine de gardiennage</span>
                                            </div>
                                            <div className="grid3 col-65">
                                                <div className='mr30'>
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>1 passage</p>
                                                    </div>
                                                </div>

                                                <div className='mr30'>
                                                    <div className='flex jc-start'>
                                                        <input type="checkbox" />
                                                            <p className='fz09 ml10'>2 passage</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </label>

                                    </div>

                                </div>

                                <div className="ta-end mt20">
                                    <input type="submit" name="submit" value="Proposer" class="btn bg3 cl5 brc3 brc1solid" />
                                </div>
                            </form>

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        </div>

    )

}

export default PlantSittingProposer