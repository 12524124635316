import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";

import { Context } from './class/Context'

import './Style/style.css'
import './Style/base.css'
import './Style/mobile.css'

import Accueil from './pages/Accueil'
import Connexion from './pages/Connexion'
import Inscription from './pages/Inscription'
import ConnexionEmail from './pages/ConnexionEmail'

import Vendre from './pages/Vendre'

import User from './class/User'

import Articles from './pages/profile-prive/Articles'
import Evaluations from './pages/profile-prive/Evaluations'
import PlantSittingPrive from './pages/profile-prive/PlantSittingPrive'
import Transactions from './pages/profile-prive/Transactions'
import TransactionsVente from './pages/profile-prive/TransactionsVente'
import Parametres from './pages/profile-prive/Parametres'
import Paiements from './pages/profile-prive/Paiements'
import PaiementsAjouterCarte from './pages/profile-prive/PaiementsAjouterCarte'

import ModifierAnnonce from './pages/profile-prive/ModifierAnnonce'

import Discussions from './pages/discussions/Discussions'
import Discussion from './pages/discussions/Discussion'

import Ajouter_avis from './pages/profile-prive/Ajouter_avis'

import Favoris from './pages/profile-prive/Favoris'

import Single from './pages/Single'

import CategoriesPage from './pages/CategoriesPage'

import Profile from './pages/profile/Profile'
import EvaluationsPublique from './pages/profile/EvaluationsPublique'
import PlantSittingPublique from './pages/profile/PlantSittingPublique'

import Page_404 from './pages/Page_404'

import Search from './pages/Search'

import Commander from './pages/Commander'
import CommanderLot from './pages/CommanderLot'

import Lot from './pages/Lot'

import CharteGreen from './pages/profile-prive/CharteGreen'

import ConnexionGoogle from './pages/social/ConnexionGoogle'
import ConnexionFacebook from './pages/social/ConnexionFacebook'

import Pages from './pages/pages/Pages'
import Contact from './pages/pages/Contact'

import ValiderEmail from './pages/ValiderEmail'

import MasquerAnnonce from './pages/profile-prive/MasquerAnnonce'
import SupprimerAnnonce from './pages/profile-prive/SupprimerAnnonce'

import MotDePasse from './pages/MotDePasse'
import BoiteOutils from './pages/pages/BoiteOutils'
import SupprimerCompte from './pages/profile-prive/SupprimerCompte'
import PorteMonnaie from './pages/profile-prive/PorteMonnaie'
import Identitee from './pages/profile-prive/Identitee'
import PlantSitting from './pages/plant-sitting/PlantSitting';
import PlantSittingAnnonce from './pages/plant-sitting/PlantSittingAnnonce';
import PlantSittingReserver from './pages/plant-sitting/PlantSittingReserver';
import PlantSittingProposer from './pages/plant-sitting/PlantSittingProposer';

function App() {

  const [session, setSession] = useState(false)

  useEffect(() => {

    User.connected(setSession)

  }, [])

  return (

    <Context.Provider value={{ session, setSession }}>

      <Router
      //basename={"/react"}
      >

        <Routes>

          <Route exact path="/" element={<Accueil />}></Route>

          <Route path="/page/:url" element={<Pages />}></Route>
          <Route path="/page/contact" element={<Contact />}></Route>
          <Route path="/page/boite-a-outils" element={<BoiteOutils />}></Route>

          <Route path="/connexion" element={<Connexion />}></Route>
          <Route path="/inscription" element={<Inscription />}></Route>
          <Route path="/connexion-email" element={<ConnexionEmail />}></Route>
          <Route path="/connexion-google" element={<ConnexionGoogle />}></Route>
          <Route path="/connexion-facebook" element={<ConnexionFacebook />}></Route>
          <Route path="/recuperer-mot-de-passe" element={<MotDePasse />}></Route>
          <Route path="/valider-email" element={<ValiderEmail />}></Route>

          <Route path="/categorie/:url" element={<CategoriesPage />}></Route>

          <Route path="/annonce/:id" element={<Single />}></Route>

          <Route path="/acheter-un-lot/:id" element={<Lot />}></Route>

          <Route path="/search" element={<Search />}></Route>

          // Profil publique

          <Route path="/profile/:id" element={<Profile />}></Route>
          <Route path="/profile/:id/evaluations" element={<EvaluationsPublique />}></Route>
          <Route path="/profile/:id/plant-sitting" element={<PlantSittingPublique />}></Route>

          // With session

          {

            session

            &&

            <>

              <Route path="/mon-profile/articles" element={<Articles />}></Route>
              <Route path="/mon-profile/evaluations" element={<Evaluations />}></Route>
              <Route path="/mon-profile/plant-sitting" element={<PlantSittingPrive />}></Route>
              <Route path="/mon-profile/transactions" element={<Transactions />}></Route>
              <Route path="/mon-profile/transactions/ventes" element={<TransactionsVente />}></Route>
              <Route path="/mon-profile/parametres" element={<Parametres />}></Route>
              <Route path="/mon-profile/paiements" element={<Paiements />}></Route>
              <Route path="/mon-profile/paiements/ajouter_carte" element={<PaiementsAjouterCarte />}></Route>
              <Route path="/mon-profile/porte-monnaie" element={<PorteMonnaie />}></Route>
              <Route path="/mon-profile/identitee" element={<Identitee />}></Route>
              <Route path="/mon-profile/modifier-annonce/:id" element={<ModifierAnnonce />}></Route>
              <Route path="/mon-profile/masquer-annonce/:id" element={<MasquerAnnonce />}></Route>
              <Route path="/mon-profile/supprimer-annonce/:id" element={<SupprimerAnnonce />}></Route>
              <Route path="/mon-profile/favoris" element={<Favoris />}></Route>
              <Route path="/mon-profile/signer-charte-green" element={<CharteGreen />}></Route>

              <Route path="/mon-profile/discussions" element={<Discussions />}></Route>
              <Route path="/mon-profile/discussion/:id" element={<Discussion />}></Route>

              <Route path="/mon-profile/ajouter_avis/:id" element={<Ajouter_avis />}></Route>

              <Route path="/mon-profile/supprimer-mon-compte" element={<SupprimerCompte />}></Route>



            </>

          }

          <Route path="/plant-sitting" element={<PlantSitting />}></Route>
          <Route path="/plant-sitting/annonce/:id" element={<PlantSittingAnnonce />}></Route>
          <Route path="/plant-sitting/reserver" element={<PlantSittingReserver />}></Route>
          <Route path="/plant-sitting/proposer" element={<PlantSittingProposer />}></Route>

          {/* <Route path="/mon-profile/discussion/:id" element={<Discussion />}></Route> */}


          <Route path="/commander" element={<Commander />}></Route>
          <Route path="/commanderLot" element={<CommanderLot />}></Route>

          <Route path="/vendre" element={<Vendre />}></Route>

          <Route path='*' element={<Page_404 />} />
          <Route path='/404' element={<Page_404 />} />

        </Routes>

      </Router>

    </Context.Provider>

  )

}

export default App;