import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import Func from '../../class/Func'

import Categories from '../../class/Categories';
import Annonces from '../../class/Annonces';

function PlantSitting() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [categorie, setCategorie] = useState(false)
    const [categories, setCategories] = useState([])

    const [annonces, setAnnonces] = useState(false)

    const [loading, setLoading] = useState(false)

    const [limit, setLimit] = useState(2)

    const [filtreTaille, setFiltreTaille] = useState(false)
    const [filtreType, setFiltreType] = useState(false)
    const [filtrePrixMin, setFiltrePrixMin] = useState(false)
    const [filtrePrixMax, setFiltrePrixMax] = useState(false)

    const getCategorie = async () => {

        let results = await Categories.categorieByUrl(url)

        if (results.success) {

            console.log(results.data)

            setCategorie(results.data)
            setCategories(results.categories)

            document.title = results.data.name + " - " + APP_NAME

            getAnnonces(results.data.idC)

        } else {

            navigate('/404')

        }

    }

    const getAnnonces = async (id, option = false, load = true) => {

        if (load) setAnnonces(false)

        if (!option) {
            setFiltreTaille(false)
            setFiltreType(false)
            setFiltrePrixMin(false)
            setFiltrePrixMax(false)
        }

        let results = await Annonces.annoncesByCategorie(id, option)

        setLoading(false)

        if (results.success) {

            setAnnonces(results.data)

        } else {

            if (results.empty) {

                setAnnonces(results.message)

            } else {

                navigate('/404')

            }

        }

    }

    const changeFilter = (id, type, val) => {

        let taille = filtreTaille
        let type_ = filtreType
        let p_min = filtrePrixMin
        let p_max = filtrePrixMax

        if (type == 'taille') {
            taille = val
            setFiltreTaille(val)
        }

        if (type == 'type') {
            type_ = val
            setFiltreType(val)
        }

        if (type == 'min') {
            p_min = val
            setFiltrePrixMin(val)
        }

        if (type == 'max') {
            p_max = val
            setFiltrePrixMax(val)
        }

        getAnnonces(id, { 'taille': taille, 'type': type_, 'min': p_min, 'max': p_max })

    }

    const voirPlus = () => {

        let taille = filtreTaille
        let type_ = filtreType
        let p_min = filtrePrixMin
        let p_max = filtrePrixMax

        setLoading(true)

        getAnnonces(categorie.idC, { 'taille': taille, 'type': type_, 'min': p_min, 'max': p_max, limit: limit }, false)

        setLimit(limit + 1)

    }

    useEffect(() => {

        // getCategorie()

        document.title = "Plant Sitting - " + APP_NAME

        document.body.classList.remove('mn')

        Func.choixSelect('#taille ._choix')

    }, [url])

    return (

        <div>

            <Header />

            <div className="plantsitting">

                <HeaderBox />

                <div className="c-0 pos-r content">

                    <div className="flex m_flex mb20">
                        <form method='POST' className='gla-form pos-r head_form' >
                            <span className='icon'>d</span>
                            <input type='search' name='query' placeholder='Rechercher une adresse ...' autoComplete="off" className='search_input' />
                        </form>

                        <Link to={'/plant-sitting/proposer'} className='btn bg3 cl1'>Je propose un plant sitting</Link>

                    </div>

                    <div className="flex">

                        <div className="col-2 aside m_p10 m_mb_40">

                            {

                                (filtreTaille || filtreType || filtrePrixMin || filtrePrixMax)

                                &&

                                <>
                                    <span className='cl4 mr5 icon' onClick={e => navigate(0)}>w</span>
                                    <span classname="fz08">Supprimer</span>
                                </>

                            }

                            <span className='cl4 t'>Nombre de plantes</span>

                            <div className="grid2 cat_filter m_flex">

                                <div>
                                    <label>Min</label>
                                    <input type="number" placeholder='1' className='prix_min col-10 brc4' value={filtrePrixMin} onChange={e => changeFilter(categorie.idC, 'min', e.target.value)} />
                                </div>

                                <div>
                                    <label>Max</label>
                                    <input type="number" placeholder='100' className='prix_max col-10 brc4' value={filtrePrixMax} onChange={e => changeFilter(categorie.idC, 'max', e.target.value)} />
                                </div>

                            </div>

                            <span className='cl4 t'>Afficher</span>

                            <div>
                                <input type="checkbox" id="show" name="show" className='mr10' />
                                <label htmlFor="show">Gardiennage</label>
                            </div>

                            <div className='mb20'>
                                <input type="checkbox" id="arrosage" name="arrosage" className='mr10' />
                                <label htmlFor="arrosage">Arrosage</label>
                            </div>

                            <div>
                                <input type="checkbox" id="show" name="show" className='mr10' />
                                <label htmlFor="show">1 Passage / semaine</label>
                            </div>

                            <div>
                                <input type="checkbox" id="arrosage" name="arrosage" className='mr10' />
                                <label htmlFor="arrosage">2 passages / semaine</label>
                            </div>

                            <span className='cl4 t'>Prix</span>

                            <div className="grid2 cat_filter m_flex">

                                <div>
                                    <label>Min</label>
                                    <input type="number" placeholder='€' className='prix_min col-10 brc4' value={filtrePrixMin} onChange={e => changeFilter(categorie.idC, 'min', e.target.value)} />
                                </div>

                                <div>
                                    <label>Max</label>
                                    <input type="number" placeholder='€' className='prix_max col-10 brc4' value={filtrePrixMax} onChange={e => changeFilter(categorie.idC, 'max', e.target.value)} />
                                </div>

                            </div>


                        </div>

                        <div className="col-78 m_mb_40">

                            <div className="services grid4">

                                <Link to={'/plant-sitting/annonce/1'} className="service bg9 brc4 cl2">
                                    <div className="place">
                                        <span className="icon fz18 mr5">i</span>
                                        <span>Paris, 9eme</span>
                                    </div>
                                    <div className="price">12 € / passage</div>

                                    <ul className='brc4'>
                                        <li><span class='icon cl4'>y</span>Arrosage</li>
                                        <li><span class='icon'>w</span>Gardiennage</li>
                                    </ul>

                                    <div className="bottom">
                                        <div className='user'>
                                            <div className="av ">
                                                <img src={Func.avatar(0)} />
                                            </div>
                                            <span className='icon'>p</span>
                                            <span>4,6</span>
                                        </div>
                                        <span className='icon cl4'>k</span>
                                    </div>

                                </Link>

                            </div>
                            {

                                annonces

                                    ?

                                    typeof annonces == 'object'

                                        ?

                                        <>

                                            <div className="grid4 articles small">

                                                {annonces.map(element => <Article data={element} key={element.idA} />)}

                                            </div>

                                            {loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>}

                                            {

                                                annonces.length % 12 == 0

                                                &&

                                                <div className='flex jc-center mt30 m_flex m_p10 m_mb_10'>
                                                    <span className='btn bg4 cl1' onClick={voirPlus}>Voir plus</span>
                                                </div>

                                            }

                                        </>

                                        :

                                        <p>Aucune annonce !</p>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        </div>

    )

}

export default PlantSitting