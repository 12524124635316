import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import Func from '../../class/Func'

import Categories from '../../class/Categories';
import Annonces from '../../class/Annonces';

function PlantSittingReserver() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [annonces, setAnnonces] = useState(false)

    const [loading, setLoading] = useState(false)

    const getAnnonce = async (id, option = false, load = true) => {

        if (load) setAnnonces(false)

        let results = await Annonces.annoncesByCategorie(id, option)

        setLoading(false)

        if (results.success) {

            setAnnonces(results.data)

        } else {

            if (results.empty) {

                setAnnonces(results.message)

            } else {

                navigate('/404')

            }

        }

    }

    useEffect(() => {

        // getCategorie()

        document.title = "Réserver un plant sitter - " + APP_NAME

        document.body.classList.remove('mn')

    }, [url])

    return (

        <div>

            <Header />

            <div className="plantsitting">

                <HeaderBox />

                <div className="single">

                    <div className='content'>

                        <div className='c'>

                            <div class="bg7 br8 p20 mt20 mb20">

                                <form action="" className='gla-form'>
                                    <label>
                                        <span>Catégorie</span>
                                        <select>
                                            <option value="0">Gardiennage</option>
                                            <option value="1">Arrosage</option>
                                        </select>
                                    </label>

                                    <label>
                                        <span>Quantité</span>

                                        <div class=" col-65">
                                            <div className="flex mb10 jc-start">
                                                <input type="checkbox" />
                                                <p class='fz09 ml10'>entre 1 & 35 plantes</p>
                                            </div>
                                            <div className="flex mb10 jc-start">
                                                <input type="checkbox" />
                                                <p class='fz09 ml10'>entre 1 & 70 plantes</p>
                                            </div>
                                            <div className="flex mb10 jc-start">
                                                <input type="checkbox" />
                                                <p class='fz09 ml10'>entre 1 & 100 plantes</p>
                                            </div>
                                        </div>
                                    </label>

                                    <label>
                                        <span>Conseignes spécifiques</span>
                                        <textarea name="description" placeholder="Je souhaite que vous chouchoutiez mes plantes pendant que je suis en vacances" required="true" ></textarea>
                                    </label>

                                </form>

                            </div>

                            <div class="flex bg7 br8 p20 mt20">

                                <p className='fz15 fw5'>33 €</p>

                                <input type="submit" name="submit" value="Envoyer une demande" class="btn bg4 cl5 brc4 brc1solid" />

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        </div>

    )

}

export default PlantSittingReserver