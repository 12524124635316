import React, { useState, useEffect } from 'react'

import { Link } from "react-router-dom"

import { PHOTOS_ROOT, AVATAR_ROOT, WEBROOT } from '../constants/general'

import Func from '../class/Func'

import User from '../class/User'

import Moment from 'react-moment'
import 'moment/locale/fr'

import Discussions from '../class/Discussions'

const HeaderProfile = () => {
    
    const [infos, setInfos] = useState(false)

    const myInfos = async () => {

        let results = await User.session(true)

        if(results.success){

            setInfos(results.data)

        }

    }

    useEffect(() => {

        myInfos()

    }, [])

    return (
        <div className="bg7 br8 p20 mt20">
            <div className="flex pos-r">

                <div className="flex m_flex">
                    <div className="av large mr40">
                        
                        <img src={ Func.avatar(infos.avatar)} className="avatar_img"/>

                    </div>

                    <div className='m_col-7 m_ml_15'>
                        <div className="flex m_flex ai-center fz09 prfl flex-wrap flex-start">

                            <span className='t cl2 fw5 m_col_10'>{ infos.name }</span>

                            { Func.note(infos.note) } <span className='ml10'>( { infos.nbr_note } avis</span>)

                        </div>

                        <div className="flex jc-start mt20 m_mt_10 ai-center">
                            <span className="icon mr10 fz13">i</span>
                            <span className='h'>{infos.pays}{infos.ville != null ? ', ' + infos.ville : "Localisation"}</span>
                        </div>

                        <div className='mt20 m_mt_10'>

                            <span>Informations vérifiées :</span>

                            <ul>
                                { infos.email_token == 0 && <li>Email</li>}
                                { infos.charte_green == 1 && <li className='cl2'><Link to={'/page/la-chartegreen'} className='cl2'>Charte Green signée</Link></li>}
                            </ul>

                        </div>

                    </div>

                </div>

                <div>
                    
                    {

                        infos.charte_green == 0

                        &&
                        
                        <Link to={'/mon-profile/signer-charte-green'} className="btn cl1 bg4 ta-center d-block mb10 m_mt_10 pulse">Signer la charte Green</Link>

                    }

                </div>

            </div>

        </div>
    )

}

const ProfileMenu = (props) => {

    let not_active = "btn cl2 bgt mr10"
    let active = "btn brc4 brc2solid cl1 bg4 mr10"

    return (
        <div className="wrap textUp fw5 mb20 m_profile_menu">
            <Link to={'/mon-profile/articles'} className={props.data == 'articles' ? active : not_active }>Pépinière</Link>
            <Link to={'/mon-profile/evaluations'} className={props.data == 'evaluations' ? active : not_active }>évaluations</Link>
            {/* <Link to={'/mon-profile/plant-sitting'} className={props.data == 'plant-sitting' ? active : not_active }>Plant-sitting</Link> */}
            <Link to={'/mon-profile/parametres'} className={props.data == 'parametres' ? active : not_active }>Paramètres</Link>
            <Link to={'/mon-profile/transactions'} className={props.data == 'transactions' ? active : not_active }>Transactions</Link>
            <Link to={'/mon-profile/paiements'} className={props.data == 'paiements' ? active : not_active }>Paiements</Link>
            <Link to={'/mon-profile/porte-monnaie'} style={{background: '#D43A72'}} className='btn cl1'>Porte-Monnaie</Link>
        </div>
    )

}

const Article = (props) => {

    return(
        <Link to={'/annonce/' + props.data.idA} className="article bg6 cl2">
            <div className="img">
                <img src={PHOTOS_ROOT + 'small/' + props.data.name + ".jpg"}/>
            </div>
            <div className="cnt">
                <div className="flex m_flex">
                    <span className='price'>{props.data.prix}€</span>
                    {/* <span className='fz08'>{props.data.nbr_favoris} favoris</span> */}
                    { props.data.stt == 1 && <span className='fz09 cl3 fw5'>Vendu</span>}
                    { props.data.stt == 2 && <span className='fz09 cl3 fw5'>Vendu</span>}
                    { props.data.stt == 3 && <span className='fz09 cl3 fw5'>Vendu</span>}
                    { props.data.stt == 5 && <span className='fz09 cl3 fw5'>Masqué</span>}
                </div>
                <span className='t h mt20'>{ props.data.titre }</span>
            </div>
        </Link>
    )

}

const DiscussionsCard = (discussion_id) => {

    const [discussions, setDiscussions] = useState(false)

    const mesDiscussions = async () => {

        let results = await Discussions.mes_discussions({limit: 12})
    
        if(results.success){
            
            setDiscussions(results.data)

        }else{

            setDiscussions(results.message)

        }
    
    }

    useEffect(() => {

        mesDiscussions()

    }, [])

    return (
        <div className='col-3 bg7 br8 p20 discussions'>

            <h2 className='mb20'>Mes discussions</h2>

            {

                discussions

                ?

                    typeof discussions == 'object'

                    ?

                    discussions.map(element => <DiscussionCard data={element} discussion_id={discussion_id.discussion_id} key={ element.id }/>)

                    :

                    <p className='fz08'>{discussions}</p>

                :

                    <div className='flex jc-center mt50 mb50'>
                        <div className="lds-dual-ring col"></div>
                    </div>

            }

        </div>
    )

}

const DiscussionCard = (props) => {

    const me = localStorage.getItem('idU')

    const discussionClick = (id) => {

        let elem = document.querySelector('#discussion_' + id)

        elem.classList.remove('fw7')

        if(elem.querySelector('.badge')) elem.querySelector('.badge').remove()

    }

    let badge = ''

    if(me == props.data.u1){

        if(props.data.new_u1 > 0) badge = <span className='badge'>{ props.data.new_u1 }</span>

    }else{

        if(props.data.new_u2 > 0) badge = <span className='badge'>{ props.data.new_u2 }</span>

    }

    let class_name = '';

    if(badge == ''){

        class_name = 'discussion'

    }else{

        class_name = 'discussion fw7'

    }

    if(props.data.id == props.discussion_id) class_name += ' active'

    return(

        <Link to={"/mon-profile/discussion/" + props.data.id } className={ class_name } id={'discussion_' + props.data.id} onClick={() => discussionClick(props.data.id) }>
                                    
            <img src={Func.avatar(props.data.avatar)} />

            <div>
                <p>{ props.data.name } { badge }</p>
                <span className="t"><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></span>
            </div>

        </Link>

    )

}

const Message = (props) => {

    const me = localStorage.getItem('idU')

    const id = props.id
    const annonce_id = props.annonce_id
    const msg = props.data

    const opt = JSON.parse(msg.opt)

    let montant = (parseFloat(opt.montant) + 0.98 + parseFloat(opt.montant) * 0.05)

    const refuser = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'refuser')
    
        if(results.success){
            
            Func.envoyeMessageBox("J'ai refusé cette offre")

        }else{

            alert(results.message)

        }

    }

    const accepter = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'accepter')
    
        if(results.success){
            
            Func.envoyeMessageBox("J'ai accepté cette offre")

        }else{

            alert(results.message)

        }

    }

    const livrer = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'envoyer')
    
        if(results.success){
            
            Func.envoyeMessageBox("J'ai envoyé le colis")

        }else{

            alert(results.message)

        }

    }

    const misere = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'misere')
    
        if(results.success){
            
            Func.envoyeMessageBox("J'ai une misère !")

        }else{

            alert(results.message)

        }

    }

    const finaliser = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'finaliser')
    
        if(results.success){
            
            // Func.envoyeMessageBox("J'ai finaliser cette vente, Je donne mon avis sur le vendeur <a href='/mon-profile/ajouter_avis/"+ results.message_id +"'>Ajouter un avis</a>")
            Func.envoyeMessageBox("Transaction finalisée.")

        }else{

            alert(results.message)

        }

    }

    const annuler = async (e) => {

        e.target.parentNode.remove()

        let results = await Discussions.repondre_message(id, msg.i, 'annuler')
    
        if(results.success){
            
            Func.envoyeMessageBox("J'ai annulé cette vente.")

        }else{

            alert(results.message)

        }

    }

    return(

        <div className={me == msg.s ? 'message bg9 p10 br8 col-8 ml20per m_col-8' : 'message bg7 p10 br8 col-8 m_col-8'}>

            <div className={me == msg.s ? 'flex flex-reverse' : 'flex'}>
                <div className='user'>
                    <div className="av">
                        <img src={ Func.avatar(msg.avatar) } />
                    </div>
                    <Link to={'/profile/' + msg.s } className="t cl2">{ msg.name }</Link>
                </div>
                <span className='fz08'><Moment interval='1000' locale="fr" fromNow>{ msg.date }</Moment></span>
            </div>

            {

                msg.type == 1 && msg.s == me && msg.stt == 0

                &&

                <div className='pt20'>
                    <span className='btn bg4 cl1 mr10'>Acheter</span>
                </div>

            }

            {

                msg.type == 2 && msg.s !== me

                &&

                    <>

                        <div className='cnt mt20'>

                            <p className='mb10'>Coucou, je te fais une offre</p>
                            <p className='mb10 fw6'>{ opt.offre } €</p>

                        </div>


                        {

                            msg.stt == 0

                            ?

                            <div>
                                <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => accepter(e)}>J'accepte</span>
                                <span className='btn brc4 brc2solid cl4 hover-cl1 hover-bg4' onClick={(e) => refuser(e)}>Je refuse</span>
                            </div>

                            :

                                msg.stt == 1

                                ?

                                    <span>Accepté</span>
                                
                                :

                                    <span>Refusé</span>

                        }

                    </>

                }

                {

                msg.type == 2 && msg.s == me

                &&

                    <div className='cnt mt20'>

                            <p className='mb10'>Tu as fait une offre pour cet article</p>
                            <p><span className='fw6'>{ opt.offre } €</span>
                            
                            {

                                msg.stt == 0

                                ?

                                    <span> En attente</span>

                                :

                                    msg.stt == 1

                                    ?

                                        <span> Accepté</span>
                                    
                                    :

                                        <span> Refusé</span>

                            }

                            </p>

                        </div> 

            }

            {
                
                msg.type == 3

                &&

                <div className='cnt'>

                    <p>{ msg.msg }</p>

                </div>
            
            }

            {
                
                // Acheteur

                msg.type == 4 && msg.s !== me

                &&

                <div className='cnt mt20'>

                    <p>Le vendeur a refusé ton offre. Tu peux toujours acheter l'article au prix initial</p>

                </div>
            
            }

            {
                
                // Vendeur

                msg.type == 4 && msg.s == me

                &&

                <div className='cnt'>

                    <p>J'ai refusé cette offre</p>

                </div>
            
            }

            {

                // Acheteur

                msg.type == 5 && msg.s == me

                &&

                <>

                    <div className='cnt'>

                        <p>Patiente pour le paiement</p>

                    </div>

                </>

            }

            {

                // Vendeur

                msg.type == 5 && msg.s !== me && msg.stt == 0

                &&

                <>
                
                    <div className='cnt mt20'>

                        <p>Maintenant, tu peux acheter cet article</p>

                    </div>

                    <div className='pt20'>
                        <Link to={'/commander?annonce=' + annonce_id + '&offre=' + msg.i} className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4'>J'achète</Link>
                    </div>

                </>

            }

            {

            // Vendeur

            msg.type == 5 && msg.s !== me && msg.stt == 1

            &&

            <>

                <div className='cnt mt20'>

                    <p>Offre acceptée</p>

                </div>

            </>

            }

            {

                // Coté vendeur - achat

                msg.type == 6 && msg.s !== me && (msg.stt == 0 || msg.stt == 1)

                &&

                <>

                    <div className='cnt pt20'>

                        <p className='d-block mb20 fw6'>{ opt.montant } €</p>

                        {

                            opt.lot !== undefined

                            ?

                            <div className='mb10 d-block'>
                                <p className='mb10'>Tu as vendu un lot avec les articles suivants :</p>
                                <p className='mb20 cl4 fw6'>{ msg.msg }</p>
                            </div>

                            :

                            <p className='mb10 d-block'>Ton article est vendu, l’acheteur a réglé les frais de port.</p>

                        }

                        <p className='mb10 d-block'>Tu as 7 jours pour télécharger, imprimer le bordereau et envoyer le colis, n’oublie pas que l’acheteur l’attend impatiemment.</p>
                        <p className='mb30 d-block'><Link to={'/image/etiquettes/' + opt.transaction_id + '.pdf'} className='cl4 fw6 underline' target="_blank">Je télécharge le bordereau d'envoi</Link></p>

                        <p className='mb10 d-block'>1. Si je dépose le colis à la Poste, j’informe l’acheteur en cliquant sur le bouton <strong>J'ai envoyé le colis</strong>.</p>
                        <p className='mb30 d-block'>2. Si je dépose le colis dans ma boîte aux lettres, Je remplis le <a href='https://www.laposte.fr/expedition-bal/formulaire' target='blank' className='cl4'><strong>formulaire en ligne</strong></a> pour programmer le passage du facteur, dès qu'il est pris en charge, j’informe l’acheteur en cliquant sur le bouton <strong>J'ai envoyé le colis</strong>.</p>

                        <p className='mb10 d-block fz08'>Je consulte la <a href='https://www.sistersgreen.fr/page/faq-colissimo-et-courrier' target='_blank' className='cl4'><strong>FAQ Colissimo</strong></a> pour plus de détails sur L’envoi en boîte aux lettres.</p>

                    </div>

                    <div className='pt20 flex ai-center'>
                        <p className='flex-end fz08 mr10'>Payé le <Moment format='DD/MM/YYYY \à HH:mm' className='ml5'>{ msg.date }</Moment></p>
                        { msg.stt == 0 && <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => livrer(e)}>J'ai envoyé le colis</span> }
                    </div>
                </>

            }

            {

                // Coté vendeur - achat - Vente annulée

                msg.type == 6 && msg.s !== me && msg.stt == 2

                &&

                <>

                    <div className='cnt pt20'>

                        <p className='mb10 d-block'>La vente est annulée car l'article n'a pas été envoyé, tu recevras une évaluation automatique négative.</p>

                    </div>

                </>

            }

            {

                // Coté acheteur - achat

                msg.type == 6 && msg.s == me && (msg.stt == 0 || msg.stt == 1)

                &&

                <>
                    <div className='cnt pt20'>
                        <p className='d-block'><span className='fw6'>{ opt.montant } €</span> <span className='fz09'>Hors frais de livraison</span></p>
                        <p className='fz09 mb20 d-block cl4'><span className='fw6 fz11'>{ montant.toFixed(2) } €</span> Frais de service inclus</p>

                        <p className='mb10'>Félicitations, tu viens de faire un geste pour la planète</p>

                        {
                            opt.lot !== undefined

                            &&

                            <div className='mb10 d-block'>
                                <p className='mb10'>Nous avons informé le vendeur que tu as craqué pour plusieurs de ses articles.</p>
                            </div>
                            
                        }

                        <p className='mb10'>Maintenant, patiente que le vendeur envoie le colis</p>

                    </div>

                    <p className='flex-end fz08'>Paiement effectué, En attente de l'envoi du colis par le vendeur.</p>
                </>

            }

            {

                // Coté acheteur - achat - Vente annulée

                msg.type == 6 && msg.s == me && msg.stt == 2

                &&

                <>

                    <div className='cnt pt20'>

                        <p className='mb10 d-block'>Ta commande est annulée car elle n'a pas été envoyée.</p>
                        <p className='mb10 d-block'>Un remboursement sera effectué sur ton compte bancaire.</p>

                    </div>

                </>

            }

            {/* Colis envoyé ------------------------------------------------------------------ */}
            {/* Colis envoyé ------------------------------------------------------------------ */}
            {/* Colis envoyé ------------------------------------------------------------------ */}

            {

                // Colis envoyé vendeur

                msg.type == 7 && msg.s == me

                &&

                <>
                    <p className='mb10'>Colis envoyé le <Moment format='DD/MM/YYYY \à HH:mm'>{ msg.date }</Moment></p>
                    <p className='mb10'>Tu peux suivre le colis en cliquant sur ce lien</p>
                    <p><Link to={'https://www.laposte.fr/outils/suivre-vos-envois'} target='_blank'>https://www.laposte.fr/outils/suivre-vos-envois</Link></p>
                </>

            }

            {

                // Colis envoyé acheteur

                msg.type == 7 && msg.s !== me

                &&

                <>
                    <div className='mt20'>
                        <p className='mb10'>Ta commande a été expédiée, tu peux suivre le colis en cliquant sur ce lien <Link to={'https://www.laposte.fr/outils/suivre-vos-envois'} target='_blank'>https://www.laposte.fr/outils/suivre-vos-envois</Link></p>
                        <p className='mb30'>Délivre au plus vite ta plante afin de lui présenter sa nouvelle maison et la chouchouter ! Tu as 4 jour pour récupérer le colis, sinon il sera automatiquement renvoyé vers le vendeur.</p>
                        
                        <p className='mb10'>1. Si l’article est conforme à sa description, je le confirme en appuyant sur sur le bouton <strong>Tout est OK</strong> et permettre au vendeur de recevoir son paiement.</p>
                        <p className='mb10'>2. Si l’article n’est pas conforme à sa description, endommagé lors du transport ou qu’il manque un article dans le lot, j’informe le vendeur en appuyant sur le bouton <strong>J’ai une misère</strong>, je garde toutes les preuves en ma possession et je donne les informations au vendeur.</p>
                        <p className='mb10'>3. Si La poste m’informe que le colis est perdu, j’informe le vendeur pour qu’il prenne contact avec le service Sisters Green.</p>
                    </div>

                    {

                        msg.stt == 0

                        &&

                        <div className='pt20 flex-end ai-center'>
                            <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => finaliser(e)} title="Finaliser la vente">Tout est OK</span>
                            <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => misere(e)} title="J'ai un problème avec le produit">J'ai une misère</span>
                            {/* <Link to={'/traking/' + opt.livraison_label} className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' target="_blank" title='Traking de votre colis'>Traking</Link> */}
                        </div>

                    }

                </>
                
            }

            {

            // Colis envoyé acheteur / Cliqué

            msg.type == 7 && msg.s !== me && msg.stt == 1

            &&

            <>
                
                <div className='mt20'>
                    <p>Ta commande a été livré</p>
                </div>

            </>

            }

            {/* Vente finalisée ------------------------------------------------------------------ */}
            {/* Vente finalisée ------------------------------------------------------------------ */}
            {/* Vente finalisée ------------------------------------------------------------------ */}

            {

                // Acheteur 
                
                msg.type == 8 && msg.s == me && msg.stt == 0

                &&

                <>
                    <div>
                        <p className='mt20'>Raconte nous comment s’est passé ta transaction avec le vendeur</p>
                    </div>

                    <div className='pt20 flex-end ai-center'>
                        <Link to={'/mon-profile/ajouter_avis/' + msg.i} className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4'>Je laisse mon évaluation</Link>
                    </div>
                </>

            }

            {

                // Acheteur 

                msg.type == 8 && msg.s == me && msg.stt == 1

                &&

                <>
                    <div>
                        <p className='mt20'>J'ai laissé mon évaluation</p>
                    </div>

                </>

            }

            {

                // Vendeur

                msg.type == 8 && msg.s !== me

                &&

                <>

                    <div>
                        <p className='mt20 mb10'>L'article a bien été reçu</p>
                        <p>Les gains de la vente seront bientôt transférés sur ton porte-monnaie. Vas sur ton profil Sisters Green et assure-toi que ton RIB soit à jour dans la section "PORTE-MONNAIE "</p>
                    </div>

                    {

                        // Acheteur 

                        msg.type == 8 && msg.s !== me && msg.stt == 1

                        &&

                        <>
                            <div>
                                <p className='mt20 mb20 cl4'>Tu as reçu une nouvelle évaluation, découvre la !</p>
                            </div>

                        </>

                    }

                    <p className='flex-end fz08'>vente finalisée le <Moment format=' DD/MM/YYYY \à HH:mm' className='ml5'>{ msg.date }</Moment></p>

                </>

            }

            {

            // Vente annulée

            // Vendeur 

            msg.type == 9 && msg.s == me

            &&

            <>
                <div>
                    <p className='mt20'>Transaction annulée</p>
                </div>

            </>

            }

            {

            // Vente annulée

            // Acheteur 

            msg.type == 9 && msg.s !== me

            &&

            <>
                <div>
                    <p className='mt20'>La transaction est annulée, un remboursement sera effectué sur ta carte de paiement.</p>
                </div>

            </>

            }

            {

                // Misère : Acheteur

                msg.type == 10 && msg.s == me

                &&

                <>

                    <div className='mt20'>
                        <p>Une misère est arrivée avec cet article, nous te suggérons de trouver une solution amiable avec le membre Sisters Green concerné par cette transaction. Si les deux parties ne trouvent aucun accord, elles doivent contacter l’adresse mail dédiée aux litiges entre utilisateurs litige@sistersgreen.fr dans les 10 jours à compter d'aujourd'hui. Pas d'inquiétude, tu seras contacté dans les plus brefs délais</p>
                    
                        {

                            msg.stt == 0

                            &&

                            <div className='pt20 flex-end ai-center'>
                                <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => finaliser(e)} title="Finaliser la vente">Je n'ai plus de misère, tout est OK</span>
                            </div>

                        }
                    
                    </div>

                </>

            }

            {

            // Misère : Vendeur

            msg.type == 10 && msg.s !== me

            &&

            <>

                <div className='mt20'>
                    <p>Une misère est arrivée avec cet article, nous te suggérons de trouver une solution amiable avec l'acheteur.</p>
                
                    {

                        msg.stt == 0

                        &&

                        <>

                            <p className='mt30'>1. Si l’article n’est pas conforme à sa description, endommagé lors du transport ou qu’il manque un article dans le lot, je procède à l’annulation de la transaction en appuyant sur le bouton <strong>J’annule la transaction</strong> ou bien je propose l’envoi d’une autre plante à mes frais, cela permettra à l’acheteur de finaliser la vente.</p>
                            <p className='mt10'>2. Si l’acheteur m’informe que le colis est perdu, je dois m’en assurer auprès de La poste et j’informe Sisters Green via : litige@sistersgreen.fr</p>
                            <p className='mt10'>3. Si les deux parties ne trouvent aucun accord, elles doivent contacter l’adresse mail dédiée aux litiges.</p>
                            
                            <p className='mt30 fz09'>Notre priorité est la bonne réputation de la communauté Sisters Green et celle de nos vendeurs</p>

                            <p className='mt10 fz09'>Aucun retour de produit n'est possible</p>

                            <div className='pt20 flex-end ai-center'>
                                <span className='btn brc4 brc2solid cl4 mr10 hover-cl1 hover-bg4' onClick={(e) => annuler(e)} title="Finaliser la vente">J'annule la transaction</span>
                            </div>

                        </>

                    }

                </div>

            </>

            }

        </div>

    )

}

export { HeaderProfile, ProfileMenu, Article, DiscussionsCard, DiscussionCard, Message };